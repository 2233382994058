<template>
  <tableComp
    :data="tableData"
    :col-configs="theadName"
    :total="total"
    @handleSizeChange="handleSizeChange"
    @handleCurrentChange="handleCurrentChange"
    maxHeight="540px"
  >
    <!-- 支付信息 -->
    <el-table-column label="支付计划" slot="billPeriod">
      <template v-slot="scope">
        {{ `第${scope.row.billPeriod}期` }}
      </template>
    </el-table-column>
    <el-table-column label="发票状态" slot="receiptStatus">
      <template v-slot="scope">
        {{ scope.row.receiptStatus ? '已开票' : '未开票' }}
      </template>
    </el-table-column>
    <el-table-column label="账单时间" slot="billDate">
      <template v-slot="scope">
        {{ handleDate(scope.row.billDate) }}
      </template>
    </el-table-column>
    <el-table-column label="支付时间" slot="payTime">
      <template v-slot="scope">
        {{ handleDate(scope.row.payTime) }}
      </template>
    </el-table-column>
    <el-table-column slot="actualPayAndRefundAmount" label="实付/退款">
      <template v-slot="scope">
        <span>{{ scope.row.actualPay ? scope.row.actualPay : '-' }} </span>
        <span v-if="scope.row.refundAmount">
          / {{ scope.row.refundAmount }}</span
        >
      </template>
    </el-table-column>
    <el-table-column slot="payTypeAndPayMethod" label="支付状态/方式">
      <template v-slot="scope">
        <span>{{ scope.row.payType ? scope.row.payType : '-' }} </span>
        <span v-if="scope.row.payMethod"> / {{ scope.row.payMethod }}</span>
      </template>
    </el-table-column>
    <!-- 用户订单 -->
    <el-table-column label="订单号" slot="orderId">
      <template v-slot="scope">
        <router-link
          style="color:#385BFF"
          target="_blank"
          :to="{
            name: 'order-list-detail',
            query: {
              orderId: scope.row.orderId,
              companyName: scope.row.companyName
            }
          }"
        >
          {{ scope.row.orderId }}
        </router-link>
      </template>
    </el-table-column>
    <el-table-column label="商品名称" slot="goodsName">
      <template v-slot="scope">
        <a
          :href="`https://www.zugeqifu.com/goodsDetail/${scope.row.goodsSn}`"
          target="_blank"
          >{{ scope.row.goodsName }}</a
        >
      </template>
    </el-table-column>
    <el-table-column label="租赁模式(租赁周期)" slot="lease">
      <template v-slot="scope">
        {{
          `${handleLeaseWay(scope.row.leaseWay)}(${
            scope.row.period ? scope.row.period : '无'
          })`
        }}
      </template>
    </el-table-column>
    <el-table-column label="起租日期(到期日期)" slot="date">
      <template v-slot="scope">
        <div>{{ handleDate(scope.row.leaseBeginDate) }}</div>
        <div>{{ handleDate(scope.row.leaseEndDate) }}</div>
      </template>
    </el-table-column>
  </tableComp>
</template>

<script>
import { formatDate } from '@/utils/common'
export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    theadName: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
    }
  },
  methods: {
    // 处理时间
    handleDate (str) {
      return str ? formatDate(str) : '-'
    },
    handleLeaseWay (val) {
      // 1 到期归还 2 到期归还 3 租满即送 4到期归还
      switch (val) {
        case 1:
          return '到期归还'
        case 2:
          return '到期归还'
        case 3:
          return '租满即送'
        case 4:
          return '到期归还'
        default:
          break
      }
    },
    handleSizeChange (val) {
      this.$emit('handleSizeChange', val)
    },
    handleCurrentChange (val) {
      this.$emit('handleCurrentChange', val)
    }
  }
}
</script>
