<template>
  <div class="main_box">
    <div class="info">
      <div class="order_info">
        <div class="title">
          订单信息
        </div>
        <div class="order_info_con con">
          <ul>
            <li>
              订单编号：<span>{{ info.orderId }}</span>
            </li>
            <li>
              订单状态：
              <span
                :style="{
                  color:
                    info.orderStatus >= 3 && info.orderStatus <= 5 ? 'red' : ''
                }"
              >
                {{ handleOrderStatus(info.orderStatus) }}
              </span>
            </li>
            <li>
              签收状态：<span>{{
                info.receiveStatus === 0 ? '未签收' : '签收'
              }}</span>
            </li>
          </ul>
          <ul>
            <li>
              到期日期：<span>{{ info.expirationTime }}</span>
            </li>
            <li>
              签约时间：<span>{{ info.contractSignTime || '-' }}</span>
            </li>
            <li>
              下单时间：<span>{{ info.orderCreateTime }}</span>
            </li>
          </ul>
          <ul>
            <li>
              商家：<span>{{ info.partnerCompanyName || '-' }}</span>
            </li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
      <div class="custom_info">
        <div class="title">
          客户信息
        </div>
        <div class="custom_info_con con">
          <ul>
            <li>
              客户名称：<span>{{ info.customerName }}</span>
            </li>
            <li>
              客户归属：<span>{{
                belongInfo.orderBelongToCustomerName || '-'
              }}</span>
            </li>
            <li>
              订单归属：<span>{{
                belongInfo.orderBelongToSalesFlowerName || '-'
              }}</span>
            </li>
          </ul>
          <ul>
            <li class="user_info">
              用户信息：
              <span>{{ info.userName }} {{ info.userPhone }}</span>
              <img
                v-if="info.userLabel === 'VIP'"
                src="~@/assets/images/vip.png"
                alt=""
              />
            </li>
            <li>
              客户类型：<span>
                {{ info.customerType === 1 ? '企业' : '个人' }}
                <!-- <span class="blacklist">黑名单</span> -->
              </span>
            </li>
            <li>
              认证手机号：<span>{{ info.authPhone }}</span>
            </li>
          </ul>
          <ul>
            <li>
              收 货 人：<span
                >{{ info.receivingName }} {{ info.receivingPhone }}</span
              >
            </li>
            <li class="address">
              <div>收货地址：</div>
              <span class="detail">{{ info.receivingAddress }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="device_info">
        <div class="title">设备信息</div>
        <ul class="device_info_con">
          <div class="img">
            <img :src="info.goodsUrl" alt="" width="100%" />
          </div>
          <li class="title_info">
            <div>{{ info.goodsName }}</div>
            <div>配置：{{ info.goodsConfig }}</div>
            <div>
              <span class="isNew" :class="{ new: info.goodsGrade === 0 }">{{
                info.goodsGrade === 0 ? '非全新' : '全新'
              }}</span>
              <span class="leaseWay" :class="{ over: info.leaseWay === 3 }">{{
                handleLeaseWay(info.leaseWay)
              }}</span>
            </div>
          </li>
          <li>
            <div><span>数量：</span>{{ info.goodsNum }}</div>
            <div><span>配货：</span>{{ info.distributionNum }}</div>
            <div><span>附件数量：</span>{{ info.attachmentsNum }}</div>
          </li>
          <li>
            <div><span>周期(月)：</span>{{ info.leaseCycle }}</div>
            <div><span>订单金额：</span>{{ info.orderPrice }}</div>
            <div><span>实付押金：</span>{{ info.depositPay }}</div>
          </li>
          <li>
            <div><span>首付金额：</span>{{ info.downPaymentAmount }}</div>
            <div><span>首付期数：</span>{{ info.downPaymentPeriod }}</div>
            <div><span>月租：</span>{{ info.monthRentAmount }}</div>
          </li>
          <!-- <li class="yuezu">
            <span class="ctitle">
              月租:
            </span>
            <div class="licon">
              <div>{{info.monthRentAmount}}</div>
              <div>￥518.00<span>（第1-12期）</span></div>
              <div>￥518.00<span>（第13-24期）</span></div>
              <div>￥518.00<span>（第25-36期）</span></div>
              <div class="jiedi">阶梯</div>
            </div>
          </li> -->
        </ul>
      </div>
      <ul class="order_remark">
        <li>
          <div>订单备注：</div>
          <ul class="remark_con">
            <li
              v-for="(item, index) in info.orderRemark &&
                JSON.parse(info.orderRemark)"
              :key="index"
            >
              {{ item.mark }} -- {{ item.username }} {{ item.updatetime }}
            </li>
          </ul>
        </li>
        <li>
          <div>买家留言：</div>
          <ul class="remark_con">
            <li>{{ info.buyerMessage || '-' }}</li>
          </ul>
        </li>
        <li>
          <div>备注信息：</div>
          <ul class="remark_con">
            <li v-for="(item, index) in remarkList" :key="index">
              {{ item.remark }} -- {{ item.operator }} {{ item.updatetime }}
              <a :href="item.accessoryUrl" v-if="item.accessoryUrl">附件</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="tabs" ref="tabs">
      <el-tabs v-model="activeName">
        <el-tab-pane label="设备序列号" name="first">
          <comp :theadName="theadNameDevice" :tableData="tableDataDevice" />
        </el-tab-pane>
        <el-tab-pane label="发货物流" name="second">
          <comp :theadName="theadNameShip" :tableData="tableDataShip" />
        </el-tab-pane>
        <el-tab-pane label="回收物流" name="third">
          <comp :theadName="theadNameRecycle" :tableData="tableDataRecycle" />
        </el-tab-pane>
        <el-tab-pane label="支付信息" name="fourth">
          <comp :theadName="theadNamePay" :tableData="tableDataPay" />
        </el-tab-pane>
        <el-tab-pane label="用户订单" name="fifth">
          <el-tabs
            v-model="userOderActiveName"
            type="card"
            style="margin-top:20px"
            @tab-click="tabClick"
          >
            <el-tab-pane label="租赁中" name="5"></el-tab-pane>
            <el-tab-pane label="待回收" name="6"></el-tab-pane>
            <el-tab-pane label="待结算" name="14"></el-tab-pane>
            <el-tab-pane label="已完结" name="7"></el-tab-pane>
            <el-tab-pane label="已关闭" name="8"></el-tab-pane>
          </el-tabs>
          <comp
            :theadName="theadNameUserOrder"
            :tableData="tableDataUserOrder"
            :total="total"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import comp from './comp'
import {
  orderDetail,
  orderDetailRemark,
  orderDetailSerial,
  orderDetailShip,
  orderDetailRecycle,
  orderDetailPay,
  orderList
} from '@/api'
import { baseURLToCrm } from '@/api/baseURL'
import { orderStatus } from '@/utils/common'
export default {
  components: {
    comp
  },
  data () {
    return {
      pageSize: 10,
      curPage: 1,
      total: 0,
      info: {},
      belongInfo: {}, // 归属信息
      remarkList: [], // 备注信息
      activeName: 'first',
      userOderActiveName: '5',
      theadNameDevice: [
        { prop: 'goodsDeviceId', label: '序列号' },
        { prop: 'goodsName', label: '商品名称' },
        { prop: 'goodsConfig', label: '配置' }
      ],
      tableDataDevice: [],
      theadNameShip: [
        { prop: 'expressNo', label: '快递单号' },
        { prop: 'logisticsMode', label: '物流方式' },
        { prop: 'logisticsCompany', label: '物流公司' },
        { prop: 'shippingTime', label: '发货日期' }
      ],
      tableDataShip: [],
      theadNameRecycle: [
        { prop: 'expressNo', label: '快递单号' },
        { prop: 'logisticsMode', label: '物流方式' },
        { prop: 'logisticsCompany', label: '物流公司' },
        { prop: 'shippingTime', label: '发货日期' }
      ],
      tableDataRecycle: [],
      theadNamePay: [
        { prop: 'typeDesc', label: '账单说明' },
        { slot: 'billPeriod' },
        { slot: 'billDate' },
        { prop: 'billPrice', label: '账单金额' },
        { slot: 'actualPayAndRefundAmount' },
        { slot: 'payTime' },
        { slot: 'payTypeAndPayMethod' },
        { slot: 'receiptStatus' }
      ],
      tableDataPay: [],
      theadNameUserOrder: [
        { prop: 'currentSell', label: '销售' },
        { slot: 'orderId' },
        { prop: 'customerName', label: '企业名称' },
        { slot: 'goodsName' },
        { prop: 'goodsDetail', label: '配置' },
        { prop: 'leaseQuantity', label: '下单数量' },
        { slot: 'num' },
        { slot: 'lease' },
        { slot: 'date' }
      ],
      tableDataUserOrder: []
    }
  },
  mounted () {
    this.getDetail().then(_ => {
      this.handleFromWorkDetail()
    })
    this.getOrderBelong()
    this.getOrderRemark()
  },
  watch: {
    // 监听$store.loginUser.storeId的数据变化 重新请求用户订单
    '$store.state.loginUserInfo.storeId': function (val) {
      this.getOrderDetailUserOrder()
    },
    activeName: {
      immediate: true,
      handler (val) {
        switch (val) {
          case 'first':
            this.getOrderDetailSerial()
            break
          case 'second':
            this.getOrderDetailShip()
            break
          case 'third':
            this.getOrderDetailRecycle()
            break
          case 'fourth':
            this.getOrderDetailPay()
            break
          case 'fifth':
            this.getOrderDetailUserOrder()
            break
          default:
            break
        }
      }
    }
  },
  methods: {
    // 从工单详情过来的操作
    handleFromWorkDetail () {
      if (this.$route.query.from === 'workOrderDetail') {
        this.activeName = 'fifth'
        this.getOrderDetailUserOrder()
        // 滚动到底部
        this.$nextTick(() => {
          document.getElementsByClassName('el-main')[0].scrollTo({
            top: this.$refs.tabs.offsetTop,
            behavior: 'smooth'
          })
        })
      }
    },
    // 获取归属信息 从crm系统获取
    getOrderBelong () {
      this.$axios(
        `${baseURLToCrm}/orderInfo/getOrderInfoBelongToByOrderId?orderId=${this.$route.query.orderId}`
      ).then(res => {
        const result = res.data
        if (result.code === 200) {
          this.belongInfo = result.data
        }
      })
    },
    // 详情数据
    getDetail () {
      return new Promise((resolve, reject) => {
        orderDetail({ orderId: this.$route.query.orderId }).then(res => {
          if (res.code === 0) {
            this.info = res.result
            resolve()
          }
        })
      })
    },
    // 订单备注信息列表
    getOrderRemark () {
      orderDetailRemark({ orderId: this.$route.query.orderId }).then(res => {
        if (res.code === 0) {
          this.remarkList = res.result
        }
      })
    },
    // 序列号
    getOrderDetailSerial () {
      orderDetailSerial({ orderId: this.$route.query.orderId }).then(res => {
        if (res.code === 0) {
          const { orderAccessoriesLists, orderDeviceLists } = res.result
          this.tableDataDevice = [...orderAccessoriesLists, ...orderDeviceLists]
        }
      })
    },
    // 发货物流
    getOrderDetailShip () {
      orderDetailShip({ orderId: this.$route.query.orderId }).then(res => {
        if (res.code === 0) {
          this.tableDataShip = res.result
        }
      })
    },
    // 回收物流
    getOrderDetailRecycle () {
      orderDetailRecycle({ orderId: this.$route.query.orderId }).then(res => {
        if (res.code === 0) {
          this.tableDataRecycle = res.result
        }
      })
    },
    // 支付信息
    getOrderDetailPay () {
      orderDetailPay({ orderId: this.$route.query.orderId }).then(res => {
        if (res.code === 0) {
          this.tableDataPay = res.result
        }
      })
    },
    // 用户订单
    getOrderDetailUserOrder () {
      // 如果是商家登录  用户订单只筛选出这个商家的订单
      const loginUserInfo = this.$store.state.loginUserInfo
      const isStore = loginUserInfo.businessRole === 'store'
      const params = {
        userId: this.info.userId,
        status: this.userOderActiveName,
        page: this.curPage,
        size: this.pageSize,
        companyId: isStore ? loginUserInfo.storeId : null
      }
      orderList(params).then(res => {
        if (res.code === 0) {
          const { records, total } = res.result
          this.tableDataUserOrder = records
          this.total = total
        }
      })
    },
    // 用户订单tabs
    tabClick () {
      this.getOrderDetailUserOrder()
    },
    handleLeaseWay (val) {
      // 1 到期归还 2 到期归还 3 租满即送 4到期归还
      switch (val) {
        case 1:
          return '到期归还'
        case 2:
          return '到期归还'
        case 3:
          return '租满即送'
        case 4:
          return '到期归还'
        default:
          break
      }
    },
    handleOrderStatus (val) {
      return orderStatus(val)
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getOrderDetailUserOrder()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getOrderDetailUserOrder()
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: 500;
  font-size: 16px;
}
.con {
  padding-bottom: 32px;
  border-bottom: 1px solid #e8e8e8;
  ul {
    display: flex;
    margin-top: 16px;
    li {
      flex: 1;
      &.address {
        display: flex;
        flex: 2;
        .detail {
          flex: 1;
        }
      }
    }
  }
}
.info {
  padding: 24px 32px 32px 32px;
  background-color: #fff;
  margin-bottom: 24px;
  li {
    span {
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .custom_info {
    padding-top: 32px;
    .blacklist {
      height: 22px;
      background: linear-gradient(138deg, #aaaaaa 0%, #4b4b4b 100%);
      border-radius: 12px;
      color: #fff;
      padding: 2px 8px;
    }
    .user_info {
      img {
        position: relative;
        width: 33px;
        height: 22px;
        left: 8px;
        top: 5px;
      }
    }
  }
  .device_info {
    padding-top: 32px;
    .device_info_con {
      padding: 16px 0 32px 0;
      display: flex;
      border-bottom: 1px solid #e8e8e8;
      .title_info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .leaseWay {
        background: #eff2ff;
        border-radius: 12px;
        padding: 2px 8px;
        color: #385bff;
        &.over {
          background: #ffeac8;
          color: #ff7f00;
        }
      }
      .isNew {
        background: #fff0d5;
        border-radius: 12px;
        border: 1px solid #a44b0a;
        padding: 1px 8px;
        margin-right: 8px;
        color: #a44b0a;
        &.new {
          background: #fbe3e0;
          color: #fd5454;
          border: 1px solid #fd5454;
        }
      }
      .img {
        width: 96px;
        height: 96px;
        border-radius: 4px;
        border: 1px solid #e9e9e9;
        margin-right: 24px;
      }
      li {
        flex: 1;
        line-height: 32px;
        span {
          color: rgba(0, 0, 0, 0.45);
        }
        &.title_info {
          flex: 1.5;
        }
        &.yuezu {
          display: flex;
          .ctitle {
            width: 35px;
          }
          .licon {
            flex: 1;
            .jiedi {
              width: 42px;
              height: 20px;
              line-height: 20px;
              text-align: center;
              background: #eff2ff;
              border-radius: 12px;
              padding: 2px 8px;
              color: #385bff;
            }
          }
        }
      }
    }
  }
  .order_remark {
    padding-top: 32px;
    > li {
      display: flex;
      line-height: 30px;
    }
    .remark_con {
      li {
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
}
.tabs {
  padding-top: 16px;
  padding-bottom: 50px;
  background-color: #fff;
  .order_log {
    margin-top: 32px;
    margin-left: 20px;
  }
}
</style>
